<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="8">
        <v-card flat class="w-100 pa-1 h-100" color="surface">
          <v-card-title class="px-1">
            <span
              :class="'text-subtitle-2 text-onSurfaceVar font-weight-bold'">Description</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <div v-if="!isTruncated || isExpanded">
              {{ task.description }}
              <span v-if="isTruncated" @click="toggleReadMore"
                class="read-more-less text-subtitle-1 text-primary font-weight-bold">Read Less</span>
            </div>
            <div v-else>
              {{ truncatedContent }}
              <span @click="toggleReadMore" class="read-more-less text-subtitle-1 text-primary font-weight-bold">Read
                More</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card flat rounded="0" color="surface" class="w-100 h-100">
          <v-card-title>
            <span class="text-subtitle-2 text-onSurfaceVar font-weight-bold">Details
            </span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-container fluid class="pa-0">
              <v-row class="my-1" align="center" no-gutters>
                <v-col class="text-body-2  font-weight-black" :cols="4"> Assignees
                </v-col>
                <v-col cols="8">
                  <span v-for="(assignees, index ) in assigneeNames.slice(0, 5)" :key="index" class="avatar-container">
                    <v-avatar rounded color="primary border-2 border-surface" size="x-small" class="avatar">
                      <span class="text-caption font-weight-black">{{ first(assignees) }}</span>
                    </v-avatar>
                    <v-tooltip activator="parent" location="bottom">
                      {{ assignees }}
                    </v-tooltip>
                  </span>
                  <span>
                    <v-avatar rounded v-if="assigneeNames.length > 5" color="surface border-2 border-surface"
                      size="x-small" class="avatar">
                      <span class="text-subtitle-2 font-weight-black">+{{ assigneeNames.length - 5 }}</span>
                    </v-avatar>
                    <v-tooltip activator="parent" location="bottom">
                      {{ assigneeNames.slice(5).join(', ') }}
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
              <v-row class="my-1" align="center" no-gutters>
                <v-col class="text-body-2  font-weight-black" :cols="4"> Reporter
                </v-col>

                <v-col cols="8" class="text-left">
                  <v-chip prepend-icon="mdi-account-circle" class="ma-1" color="primary" rounded size="small">
                    {{ taskActorName }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row class="my-1" align="center" no-gutters>
                <v-col class="text-body-2 font-weight-black" :cols="4"> Due Date </v-col>
                <v-col cols="8" class="text-left text-primary pl-2">
                  <span>{{ formattedDueDate }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card flat class="w-100 my-2 pa-1" color="surface">
          <v-card-title class="px-1">
            <span
              :class="'text-subtitle-2 text-primary font-weight-bold'">Available
              Actions</span>
          </v-card-title>
          <v-card-item class="py-2">
            <v-container>
              <v-row>
                <v-col :cols="9" class="d-flex align-center">
                  <v-select :items="taskActionOptions" v-model="actionSelection" item-title="title" item-value="value"
                    label="Select Action" variant="outlined" dense class="w-100" color="primary"
                    :disabled="actionInProgress" />
                </v-col>
                <v-col :cols="3" class="my-1 d-flex justify-center">
                  <template v-if="!actionInProgress">
                    <v-btn color="primary" class="w-100 mx-1" :disabled="!actionSelection" @click="handleActionStart"
                      rounded="false" elevation="3">
                      <v-icon class="mx-1">mdi-play</v-icon>
                      <span class="text-body-2 font-weight-bold">Start</span>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn color="primary" class="w-100 mx-1" :disabled="!actionSelection" @click="handleActionStop"
                      rounded="false" elevation="3" variant="tonal">
                      <v-icon class="mx-1">mdi-check-all</v-icon>
                      <span class="text-body-2 font-weight-bold">Complete</span>
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card flat class="w-100 my-2 pa-1" color="surface">
          <v-card-title class="px-1">
            <span
              :class="'text-subtitle-2 text-primary font-weight-bold'">Activity</span>
          </v-card-title>
          <v-card-item class="py-2">
            <v-tabs v-model="activityTab" color="primary" class="bg-surface text-primary" density="compact">
              <v-tab value="comments">
                <v-icon start size="small">
                  mdi-comment-bookmark
                </v-icon>
                <span class="text-caption font-weight-bold text-capitalize">{{ TaskActivities.COMMENTS }}</span>
              </v-tab>
            </v-tabs>
            <v-window v-model="activityTab" class="w-100 window-container">
              <v-window-item :value="TaskActivities.COMMENTS">
                <v-card flat color="surface">
                  <v-card-text v-if="listComments?.length === 0" class="text-center">
                    <span style="opacity:0.75;" class="text-subtitle-2 font-weight-bold text-primary">No comments
                      yet</span>
                  </v-card-text>
                </v-card>
                <quill-text-editor @save-comment="saveComment" />
                <div v-for="(comment, index) in listComments" :key="index">
                  <v-card flat class="pa-4 mb-2">
                    <v-row>
                      <v-col cols="4">
                        <div class="text-h7 font-weight-bold">{{ comment.author.firstName }} {{ comment.author.lastName
                          }}
                        </div>
                        <div class="text-caption mt-2">{{ formatTimestamp(comment.createdAt) }}</div>
                      </v-col>
                      <v-col cols="8" class="d-flex justify-start align-center">
                        <div class="text-body-2">{{ comment.content.message }}</div>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </v-window-item>
            </v-window>

          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSuccessMessage" color="success" location="top right" class="mt-16">Comment added
      successfully
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showErrorMessage" color="error" location="top right" class="mt-16">{{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </v-container>
</template>
<script setup lang="ts">
import { ITask, ITaskActor } from "@/interfaces/task.interface";
import { PropType, watch, ref, computed, onMounted } from "vue";
import { TaskActivities, } from "../../../enums/task.enum";
import QuillTextEditor from "@/components/common/comments/QuillTextEditor.vue";
import moment from "moment";
import { ITaskQueue, ITaskActionDefinition } from '../../../interfaces/task.interface';
import { useEventBus } from '@vueuse/core';
import { taskActionEventBusKey } from '@/events/bus-keys/task-events.bus-keys';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from "@/interfaces/user.interface";
import { formatTimestamp } from "../../../composables/FormUtility";
import { capitalize, compact, map, first } from "lodash";
import { CommentInput, IComment } from "@/interfaces/comment.interface";
import { CommentContentType, CommentContextType, CommentEntityType, CommentType } from "@/enums/comment.enum";
import { CommentStatus } from '../../../enums/comment.enum';
import Loader from '../../../components/common/Loader.vue';
import { AppActivityEventType, AppActivityTrackerTriggerType } from "@/enums/app-activity-tracker.enum";
import { newActivityStartedEventBusKey, resetActivityStartTimeEventBusKey } from "@/events/bus-keys/time-tracking-events.bus-keys";
import { useServerTimeSyncStore } from '../../../store/modules/server-time-sync.store';


const props = defineProps({
  task: {
    type: Object as PropType<ITask>,
    required: true,
  },
  taskActors: {
    type: Array as PropType<ITaskActor[]>,
    required: true,
  },
  taskQueue: {
    type: Object as PropType<ITaskQueue>,
    required: false,
  },
  taskActionDefinitions: {
    type: Object as PropType<ITaskActionDefinition[]>,
    default: [] as ITaskActionDefinition[],
  },
  currentUser: {
    type: Object as PropType<IUser>,
    required: true,
  },
  addCommentHandler: {
    type: Function as PropType<(createCommentInput: CommentInput) => Promise<IComment>>,
    required: true,
  },
  listCommentsLoader: {
    type: Function as PropType<(entityId: string) => Promise<IComment[]>>,
    required: true,
  },
  isPaused: {
    type: Boolean,
    default: false,
  },
  isPatientProfileTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  isTaskPopupClosed: {
    type: Boolean,
    default: false,
    required: false,
  }
});

const { task, taskActionDefinitions, currentUser, taskActors, listCommentsLoader, addCommentHandler } = props;
const listComments = ref<IComment[]>([]);

const taskActivityEventBus = useEventBus(taskActionEventBusKey);
const resetActivityStartTimeEventBus = useEventBus(resetActivityStartTimeEventBusKey);
const newActivityStartedEventBus = useEventBus(newActivityStartedEventBusKey);

const serverTimeSyncStore = useServerTimeSyncStore();

const formattedDueDate = ref('');
const activityTab = ref(TaskActivities.COMMENTS);
const actionSelection = ref<ITaskActionDefinition>();
const actionInProgress = ref(false);
const taskActorName = ref('');
const assigneeNames = ref<string[]>(['']);

const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('');
const loading = ref(false);
const actionStart = ref(0);

const isExpanded = ref<boolean>(false);
const truncatedContent = computed(() => {
  const taskDescription = task.description || '';
  const maxLength = 250;

  if (taskDescription.length > maxLength && !isExpanded.value) {
    return taskDescription.substring(0, maxLength) + '...';
  } else {
    return taskDescription;
  }
});

const isTruncated = computed(() => task.description !== undefined && task.description !== null && task.description.split(' ').length > 100);
const toggleReadMore = () => {
  isExpanded.value = !isExpanded.value;
  return isExpanded.value;
};

const taskActionOptions = computed(() => {
  return taskActionDefinitions.map((action) => {
    return {
      title: action.label,
      value: action,
    };
  });
});

const taskContext = () => {
  return {
    actionStart: actionStart.value,
    //@ts-ignore
    actionAt: GoTime.now(),
    actionDefinition: actionSelection.value!,
    id: uuidv4(),
    orgId: task.orgId,
    task,
    actor: currentUser
  }
}

const handleActionStart = () => {
  newActivityStartedEventBus.emit();
  actionInProgress.value = true;
  //@ts-ignore
  actionStart.value = GoTime.now();
  taskActivityEventBus.emit({
    type: AppActivityEventType.TASK_ACTIVITY,
    triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
    ...taskContext(),
    actionState: 'ACTIVE',
  });
};

const handleActionStop = () => {
  actionInProgress.value = false;
  taskActivityEventBus.emit({
    type: AppActivityEventType.TASK_ACTIVITY,
    triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
    ...taskContext(),
    actionState: 'COMPLETE',
  });
};

const handleActionPause = () => {
  taskActivityEventBus.emit({
    type: AppActivityEventType.TASK_ACTIVITY,
    triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
    ...taskContext(),
    actionState: 'PAUSED',
  });
  //@ts-ignore
  actionStart.value = GoTime.now();
};

const handleActionResume = () => {
  newActivityStartedEventBus.emit();
  //@ts-ignore
  actionStart.value = GoTime.now();
  taskActivityEventBus.emit({
    type: AppActivityEventType.TASK_ACTIVITY,
    triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
    ...taskContext(),
    actionState: 'RESUMED',
  });
};

const getAssigneeNames = (task: ITask): string[] =>
  compact(map(task.assignees, (assigneeId) => {
    const actor = taskActor(assigneeId);
    return actor ? capitalize(actor.name) : null;
  })) as string[];

watch(
  () => props.isPaused,
  (updatedIsPaused) => {
    updatedIsPaused ? handleActionPause() : handleActionResume();
  }, {
  immediate: true
}
);

watch(
  () => props.task,
  (newTask) => {
    task.description = newTask.description
    formattedDueDate.value = moment(newTask.dueDate).isValid()
      ? moment(newTask.dueDate).format("MMM DD, YYYY")
      : "Not available";
    taskActorName.value = taskActor(newTask.reporterId)?.name as string;
    assigneeNames.value = getAssigneeNames(newTask);
  }
);

watch(() => props.isTaskPopupClosed, (newVal) => {
  if (newVal && actionInProgress.value && !props.isPaused) {
    handleActionStop();
  }
});

onMounted(async () => {
  formattedDueDate.value = moment(props.task.dueDate).isValid()
    ? moment(props.task.dueDate).format("MMM DD, YYYY")
    : "Not available";
  taskActorName.value = taskActor(task.reporterId)?.name as string;
  assigneeNames.value = getAssigneeNames(task);
  if (listCommentsLoader) {
    const response = await listCommentsLoader(task.id);
    listComments.value = response;
  }

  resetActivityStartTimeEventBus.on(e => {
    //@ts-ignore
    actionStart.value = GoTime.now();
  })

});

const taskActor = (actorId: string) => props.taskActors.find(actor => actor.id === actorId);

const saveComment = async (commentContent: string) => {
  const commentInput: CommentInput = {
    entityId: task.id,
    entityType: CommentEntityType.TASK,
    commentContext: {
      id: task.id,
      type: CommentContextType.TASK_INFO,
    },
    content: {
      contentType: CommentContentType.TEXT,
      message: commentContent,
      currentState: CommentStatus.CREATED
    },
    access: {
      read: 'All'
    },
    isPinned: true,
    type: CommentType.COMMENT,
    tags: [task.id]
  };
  try {
    loading.value = true;
    await addCommentHandler(commentInput);
    listComments.value = await listCommentsLoader(task.id);
    loading.value = false;
    showSuccessMessage.value = true;
  }
  catch (error) {
    loading.value = false;
    const { message } = error as Error;
    errorMessage.value = message;
    showErrorMessage.value = true;
  }
}

</script>

<style scoped lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more-less {
  cursor: pointer;
  text-decoration: underline;
}

.avatar-container {
  position: relative;
  margin-right: -5px;
}

.avatar-container:last-child {
  margin-right: 0px;
}
</style>
