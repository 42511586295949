export enum BillingCalculationStrategy {
  REUSE_UNBILLABLE_QHCP_MINUTES = "REUSE_UNBILLABLE_QHCP_MINUTES",
  REDISTIBUTE_UNBILLABLE_QHCP_AND_STAFF_MINUTES = "REDISTIBUTE_UNBILLABLE_QHCP_AND_STAFF_MINUTES",
  USE_ONLY_QHCP_MINUTES = "USE_ONLY_QHCP_MINUTES",
  USE_ONLY_STAFF_MINUTES = "USE_ONLY_STAFF_MINUTES",
}

export enum CPTCodeBucket {
  QHCP = 'QHCP',
  STAFF = 'STAFF',
  OTHER = 'OTHER'
}

export enum CPTCodeBillableResource {
  DAYS_CHECKED_IN = 'DAYS_CHECKED_IN',
  TIME_SPENT = 'TIME_SPENT'
}

export enum BillingSyncStatus {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED'
}