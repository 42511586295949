<template>
  <v-card min-height="600px" flat>
    <div class="py-5 px-3 text-onSurfaceVar w-100 bg-surfContainerHigh">
      <v-row>
        <v-col cols="11">
          <div class="text-center text-h5 font-weight-black pl-10">
            Document upload
          </div>
        </v-col>
        <v-col cols="1">
          <v-btn :loading="loading" density="compact" @click="isCloseDialogWithoutDocsUpload" :rounded="false"
            size="large" icon="mdi-close"></v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card-item class="pl-8 pr-12 mt-8 d-flex justify-center">
      <v-file-input :disabled="loading" :accept="acceptFileTypes" :key="fileInputKey" :show-size="false" small-chips
        prepend-icon="" multiple @update:model-value="handleFileInput" rounded style="width: 800px; min-height: 200px"
        :clearable="false" variant="outlined" @drop="handleDrop" @dragover.prevent>
        <template v-slot:prepend-inner>
          <div style="width: 800px; min-height: 200px" class="text-h6 d-flex justify-center items-center">
            <v-icon color="primary" size="100">mdi-file-upload-outline</v-icon>
            Browse or drag files here
          </div>
        </template>
        <template v-slot:selection="{}"></template>
      </v-file-input>
    </v-card-item>
    <v-card-item v-if="!isEmpty(uploadedFiles)">
      <v-list class="pa-4" style="max-height: 480px; overflow-y: scroll">
        <v-list-item v-for="(file, index) in uploadedFiles" :key="file.documentId" density="compact">
          <v-card flat class="pt-4 mb-3 bg-surfContainerHigh" elevation="2">
            <v-row>
              <v-col cols="1">
                <v-icon size="35" class="cursor-pointer pl-6">
                  mdi-file-outline
                </v-icon>
              </v-col>
              <v-col cols="3">
                <v-card-title>{{ file.name }}</v-card-title>
                <v-card-subtitle>{{ (file.size / 1024).toFixed(2) }} KB</v-card-subtitle>
              </v-col>
              <v-col cols="4">
                <v-text-field density="compact" :rules="[
                  (value) => !!value || 'Title is required',
                  (value) =>
                    value.length <= 120 ||
                    'Title exceeds the limit of 120 characters',
                ]" v-model="file.title" label="Title" variant="outlined" />
              </v-col>
              <v-col cols="2">
                <v-select density="compact" v-model="file.tag" :items="documentTypes" label="Select file tag"
                  variant="outlined" />
              </v-col>
              <v-col cols="1">
                <template v-if="file.progress! > 0 && file.progress! < 100">
                  <v-btn :rounded="false" color="error" icon="mdi-close" class="mt-1" density="compact"
                    @click="terminateUpload(file.documentId, index)"></v-btn>
                </template>
                <v-icon v-else size="35" class="cursor-pointer" color="primary" @click="deleteFile(index, file)">
                  mdi-delete
                </v-icon>
              </v-col>
              <v-col cols="1">
                <template v-if="file.status === FileUploadingStatus.FAILED">
                  <v-icon size="35" color="error" class="cursor-pointer pt-2">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <template v-else-if="file.progress! > 0 && file.progress! < 100">
                  <v-progress-circular color="indigo-darken-2" :model-value="file.progress"></v-progress-circular>
                </template>
                <template v-else-if="file.progress! === 100">
                  <v-icon class="mt-2" color="green">mdi-check-circle</v-icon>
                </template>
              </v-col>
            </v-row>
          </v-card>
        </v-list-item>
      </v-list>
    </v-card-item>
    <v-card-item >
      <div>
        <p v-if="isUploadFailed" class="text-h6 text-error py-4 text-center">
          <v-icon class="mx-2 mb-1">mdi-window-close</v-icon>
          Some files have failed to upload. Please remove them and try again.
        </p>
      </div>
      <div class="d-flex justify-center my-5">
        <v-btn :loading="loading" :disabled="uploadedFiles.length > 10 || isUploadDisabled" width="400px" size="large"
          rounded="false" variant="flat" @click="documentCreation">Upload</v-btn>
        <p v-if="uploadedFiles.length > 10" class="text-h6 text-error py-4 text-center">
          You can only upload up to 10 files at a time.
        </p>
      </div>

    </v-card-item>
  </v-card>

  <v-snackbar color="success" class="text-white" v-model="isSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="isSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" class="text-white" v-model="isErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="isErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>

  <v-dialog width="400px" v-model="isAlertMsg">
    <v-card>
      <v-card-title class="text-primary">Confirm</v-card-title>
      <v-card-item>
        The upload hasn't completed yet. Closing the window now will cancel the upload. Are you sure you want to
        proceed?
      </v-card-item>
      <v-card-actions class="px-4">
        <v-btn elevation="2" rounded="false" class="mx-2" density="compact" variant="tonal" @click="isAlertMsg = false">
          No</v-btn>
        <v-btn elevation="2" rounded="false" density="compact" variant="flat" @click="deleteAllUploadFiles"
          color="primary">yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from "vue";
import { difference, first, isEmpty, split } from 'lodash';
import { v4 as uuidv4 } from "uuid";
import { ActionStatus, DocumentType, FileTypes, FileUploadingStatus } from "../../enums/document-upload.enum";
import * as tus from "tus-js-client";
import { DocumentInput, IDocument, IUploadFile } from "@/interfaces/document-upload.interface";
import moment from "moment";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps({
  getFileUploadToken: {
    type: Function as PropType<(patientId: string) => Promise<string>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
  createPatientDocument: {
    type: Function as PropType<(documentInput: DocumentInput) => Promise<IDocument>>,
    required: true,
  },
  isFileDeleting: {
    type: Boolean,
    required: true,
  }
});

const { getFileUploadToken, patientId, createPatientDocument } = props;

const emits = defineEmits(["close", "deleteUploadFile", "deleteUploadedFiles"]);
const fileInputKey = ref(0);
const secreteToken = ref('');
const loading = ref(false);
const isSuccessMessage = ref(false);
const isErrorMessage = ref(false);
const successMessage = ref('');
const errorMessage = ref('');
const queryClient = useQueryClient();
const isAlertMsg = ref(false);
const uploadInstances = new Map();
const isUploadFailed = ref(false);


const uploadedFiles = ref<IUploadFile[]>([]);

const documentTypes = Object.values(DocumentType);
const acceptFileTypes = Object.values(FileTypes);


const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer ?.files || []);

    files.forEach((file) => {
        const {
            name,
            type,
            size
        } = file;

        const isFileAlreadyUploaded = uploadedFiles.value.some(
            (uploadedFile) =>
            uploadedFile.name === name &&
            uploadedFile.size === size &&
            uploadedFile.type === type
        );

        if (isFileAlreadyUploaded) {
            isErrorMessage.value = true;
            errorMessage.value = `One or more file(s) with duplicate file are not uploaded`;
            return;
        }

        handleFileInput(files);

    });
};

const handleFileInput = (files: File | File[]) => {
  if (!files || (Array.isArray(files) && isEmpty(files))) {
    return;
  }

  const fileArray = Array.isArray(files) ? files : [files];

  if (uploadedFiles.value.length + fileArray.length > 10) {
    isErrorMessage.value = true;
    errorMessage.value = 'You can only upload up to 10 files at a time.';
    return;
  }

  const maxFileSize = import.meta.env.VITE_TUS_FILE_SIZE_LIMIT * 1024 * 1024;

  const invalidFiles = fileArray.filter(
    (file) => file.size > maxFileSize || !acceptFileTypes.includes(file.type as FileTypes)
  );

  if (!isEmpty(invalidFiles)) {
    isErrorMessage.value = true;
    errorMessage.value = invalidFiles.some((file) => file.size > maxFileSize)
      ? `One or more files exceed the ${import.meta.env.VITE_TUS_FILE_SIZE_LIMIT} MB limit.`
      : `One or more files are of an invalid type. Please ensure all files are of a supported type.`;
    return;
  }

  const uploadedFileDuplicates = fileArray.filter((file) =>

        uploadedFiles.value.some(
            (uploadedFile) =>
            uploadedFile.name === file.name &&
            uploadedFile.size === file.size &&
            uploadedFile.type === file.type
        )
    );

    const filesToUpload = difference(fileArray, uploadedFileDuplicates);

    filesToUpload.forEach((file) => {
    const { name, type, size, } = file;
    uploadedFiles.value.push({
      name,
      size,
      type,
      documentId: uuidv4(),
      title: `${split(type, '/')[0]}_${split(type, '/')[1]}_${moment().format('YYYYMMDDHHmmssSSS')}`,
      tag: first(documentTypes!)!,
      file,
      progress: 0,
      status: FileUploadingStatus.UPLOADING,
    });
  });
  handleUpload();
};

const isUploadDisabled = computed(() => {
  return (
    isEmpty(uploadedFiles.value) ||
    uploadedFiles.value.some(
      (file) =>
        !file.title ||
        file.title.length > 120 ||
        file.progress! < 100 ||
        file.status === FileUploadingStatus.FAILED
    )
  );
});


const deleteFile = (index: number, file: IUploadFile) => {
  uploadedFiles.value.splice(index, 1);
  fileInputKey.value++;
  emits('deleteUploadFile', [file]);
};

const handleUpload = () => {
  for (const file of uploadedFiles.value) {
    const { name, type, documentId, status } = file;
    if (status !== FileUploadingStatus.COMPLETED) {

      const upload = new tus.Upload(file.file, {
        endpoint: import.meta.env.VITE_TUS_ENDPOINT,
        chunkSize: import.meta.env.VITE_CHUNK_SIZE || 5242880,
        retryDelays: [0, 1000, 3000, 5000],
        metadata: {
          filename: name,
          filetype: type,
          token: secreteToken.value,
          documentId: documentId,
          documentKey: name,
          contentType: type,
        },
        onError(error) {
          file.status = FileUploadingStatus.FAILED;
          console.log('failed to upload', error);
        },
        onProgress(bytesUploaded, bytesTotal) {
          const progress = (bytesUploaded / bytesTotal) * 100;
          file.progress = progress;
        },
        onSuccess() {
          file.progress = 100;
          file.status = FileUploadingStatus.COMPLETED;
        },
      });
      uploadInstances.set(documentId, upload);
      upload.start();
    }
  }
};

const terminateUpload = (documentId: string, index: number) => {
  const upload = uploadInstances.get(documentId);
  if (upload) {
    uploadedFiles.value.splice(index, 1);
    fileInputKey.value++;
    upload.abort(true)
      .then(() => {
        const file = uploadedFiles.value.find((f) => f.documentId === documentId);
        if (file) {
          file.status = FileUploadingStatus.ABORTED;
        }
      })
      .catch((error: Error) => {
        console.error(`Failed to abort upload for documentId ${documentId}:`, error);
      });
  }
};


const documentCreation = async () => {
  loading.value = true;

  try {
    const uploadPromises = uploadedFiles.value.map(async (file) => {
      const { documentId, name, title, tag } = file;
      const documentInput: DocumentInput = {
        documentId,
        title,
        documentKey: name,
        patientId,
        type: tag,
        action: ActionStatus.UPLOAD
      };

      await createPatientDocument(documentInput);
    });

    await Promise.all(uploadPromises);

    isSuccessMessage.value = true;
    successMessage.value = 'Document upload successfully.';
    uploadedFiles.value = [];
    fileInputKey.value++;
    queryClient.invalidateQueries({ queryKey: ['patientDocuments'] })
  } catch (error) {
    isErrorMessage.value = true;
    errorMessage.value = (error as Error).message;
  } finally {
    loading.value = false;
  }
}

watch(
  () => uploadedFiles.value,
  (newFiles) => {
    const failedFiles = newFiles.filter((file) => file.status === FileUploadingStatus.FAILED);
    if (!isEmpty(failedFiles) && !isErrorMessage.value) {
      isUploadFailed.value = true;

    }
    else if (isEmpty(failedFiles)) {
      isUploadFailed.value = false;
    }
  },
  { deep: true, immediate: true }
);

const generateSecurityToken = () => {
  getFileUploadToken(patientId)
    .then((data) => {
      secreteToken.value = data;
    })
    .catch((error) => {
      isErrorMessage.value = true;
      errorMessage.value = (error as Error).message;
    });
}

const isCloseDialogWithoutDocsUpload = () => {
  if (!isEmpty(uploadedFiles.value)) {
    isAlertMsg.value = true;
  }
  else {
    emits('close')
  }
}

const deleteAllUploadFiles = () => {
  isAlertMsg.value = false;
  emits('deleteUploadedFiles', uploadedFiles.value);
}

watch(() => props.isFileDeleting, (newValue) => {
  loading.value = newValue;
})

onMounted(() => {
  generateSecurityToken();
})

</script>
