import {
  listAssignees,
  listTaskQueueItems,
  listPatientTask,
  updateTaskStatus,
  getOrgTaskStatusStates,
  searchPatientsList,
  createTask,
  editTaskDetails,
} from "./task.service";
import { createNote, getNotes, assignTaskToNote, updatePatientNote } from "./note.service";
import { getLoggedInUserInfo } from "./common.service";
import {
  getPatientVitalsThresholdConfig,
  savePatientVitalsThresholdConfig,
} from "./vital.service";
import {
  getServiceProviders,
  listSourceDefinitions,
  getPatientSourceExternalIds,
  getOrgDevicesFromQardio,
  savePatientSource,
  listPatientSource,
  listSources,
  listPatientSources,
} from "./source.service";
import {
  getPatientById,
  getPatientByPatientId,
  getPatientProviders,
  savePatientEvent,
  updatePatient,
} from "./patient.service";
import {
  getLookBackRanges,
  getPatientDataSources,
  getPatientReadings,
} from "./patientdata.service";
import { assignInventoryItem, createShipInventoryItem, getInventoryItemTypesByServiceProviderId } from "./inventory.service";
import {
  getUserTimeLogs,
  createAddTimeLog,
  listActivities,
  deleteTimeLog
} from "./timelog.service";
import { getPaitentCompliance } from "./patient-compliance.service";
import { createComment, listComments } from "./comment.service";
import {
  getPatientProgramConsents,
  getProgramEConsentDownloadUrl,
  getProgramEConsentTemplate,
  getProgramEConsentUploadUrl,
  signPatientProgramConsent,
  subscribePrograms,
  unSubscribeProgram,
} from "./patient-progam.service";
import { IPatientData } from "../interfaces/Service.interface";
import {
  getPatientBillingConfig,
  savePatientBillingConfig,
} from "./billing.service";
import { getPatientReviewedStatus, markInsightAsReviewed } from "./patient-program-insight.service";
import { getAllOrgServiceProviders } from "./org-service-provider.service";
import { IAssigneeItem, ICreateTaskInput, IRequestorItem, ITaskQueueItem, UpdateTaskInput } from "@/interfaces/task.interface";
import { IOrganizationUserFilter } from "@/interfaces/IOrganization";
import { getAllPrograms } from "./program.service";
import { getPatientLinkedUsers, updateUserPatientLinkStatus } from "./PE/userPatientLink.service";
import { snapshotPatientInfo } from "./snapshot/patient-info-snapshot.service";
import { createPatientDocument, deletePatientUploadedDocument, downloadUploadedFile, getFileUploadToken, patientDocumentLists } from "./file-uplod.service";

export const getPatientMonitorDependencies = {
  listTaskQueue: listTaskQueueItems,
  createNote,
  getNotes,
  assignTaskToNote,
  listAssignees: {
    dataLoader: listAssignees,
    description: "",
    label: "Assignees",
  },
  getLoggedInUserInfo,
  savePatientVitalsThresholdConfig,
  getPatientVitalsThresholdConfig,
  getServiceProviders,
  listSourceDefinitions,
  getPatientSourceExternalIds,
  getOrgDevicesFromQardio,
  savePatientSource,
  listPatientSource,
  getPatientByPatientId,
  getPatientProviders,
  getLookBackRanges: {
    dataLoader: getLookBackRanges,
    description: "",
    label: "Look back range",
  },
  getPatientDataSources: {
    dataLoader: getPatientDataSources,
    description: "",
    label: "Source",
  },
  getPatientReadings,
  listPatientSources,
  listSourcesInput: {
    dataLoader: listSources,
    description: "Select a device",
    label: "Device",
  },
  assignInventoryItem,
  listPatientTask,
  getPatientTimeLogs: getUserTimeLogs,
  listActivities: {
    dataLoader: listActivities,
    description: "Select a activity",
    label: "Activity",
  },
  createAddTimeLog,
  getPatientProgramCycles: getPaitentCompliance,
  listComments,
  updateTaskStatus,
  patientProgramConsents: getPatientProgramConsents,
  getProgramEConsentDownloadUrl,
  getProgramEConsentUploadUrl,
  signPatientProgramConsent,
  getProgramEConsentTemplate,
  subscribePrograms,
  unSubscribeProgram,
  updatePatient,
  getPatientBillingConfig,
  savePatientBillingConfig,
  markInsightAsReviewed,
  getPatientReviewedStatus,
  listTaskActivities: listActivities,
  createComment,
  getOrgTaskStatusStates,
  inventoryItemInput: {
    dataLoader: getInventoryItemTypesByServiceProviderId,
  },
  createShipInventoryItem,
  getAllOrgServiceProviders,
  requestorInput: {
    label: "Patient",
    description: "Enter Patient",
    dataLoader: async (search: string) => {
      const requestors = await searchPatientsList(search);
      return requestors;
    },
    validationRules: [
      (v: IRequestorItem) => !!v || "Requestor is required",
    ],
  },
  assigneeInput: {
    label: "Assignees",
    description: "Enter Assignees",
    dataLoader: async (
      OrganizationUserFilterInput: IOrganizationUserFilter
    ) => {
      const assignees = await listAssignees(
        OrganizationUserFilterInput
      );
      return assignees;
    },
    validationRules: [
      (v: IAssigneeItem[]) => (!!v && v.length >= 1) || "At least one assignee is required",
    ],
  },
  addTaskHandler: (createTaskInput: ICreateTaskInput) => {
    const createdTask = createTask(createTaskInput);
    return createdTask;
  },
  editTaskDetail: (updateInput: UpdateTaskInput) => {
    const response = editTaskDetails(updateInput);
    return response;
  },
  taskQueueInput: {
    label: "TaskQueue Label",
    description: "TaskQueue Description",
    dataLoader: async () => {
      const taskQueues = await listTaskQueueItems();
      return taskQueues;
    },
    validationRules: [
      (v: ITaskQueueItem) => !!v || "Please select a task queue",
    ],
  },
  savePatientEvent,
  deleteTimeLog,
  getAllPrograms,
  listOrgServiceProviders: getAllOrgServiceProviders,
  patientDetail: getPatientById,
  getPatientLinkedUsers,
  updateUserPatientLinkStatus,
  snapshotPatientInfo,
  getFileUploadToken,
  createPatientDocument,
  patientDocumentLists,
  deletePatientUploadedDocument,
  downloadUploadedFile,
  updatePatientNote,
} as IPatientData;
