<template>
  <v-card class="w-100 h-100" color="surface" elevation="2">
    <v-card-title class="text-primary">

      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="4" class="d-flex align-center justify-start">
            <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="transparent">
              <v-chip color="primary" class="mx-2 pa-2" elevation="2">

                <span class="text-subtitle-2 text-primary font-weight-bold"> Days &nbsp; </span>
                <span class="text-subtitle-1 font-weight-bold">{{ data?.cycleDays || 'N/A' }}</span>
              </v-chip>
              <v-chip color="primary" class="mx-2 pa-2" elevation="2">
                <span class="text-subtitle-2 text-primary font-weight-bold"> Patients &nbsp; </span>
                <span class="text-subtitle-1 font-weight-bold">{{ data?.patientBillingsInCycle ?
                  data?.patientBillingsInCycle.length : 0 }}</span>
              </v-chip>
              <v-chip color="primary" class="mx-2 pa-2" elevation="2">
                <span class="text-subtitle-2 text-primary font-weight-bold"> Start Date
                  &nbsp;</span>
                <span class="text-subtitle-1 font-weight-bold">{{ data?.cycleStart ?
                  moment(data.cycleStart).format("MM/DD/YYYY")
                  : 'N/A' }}</span>
              </v-chip>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-end">
            <cycle-selector :org-cycle-type="OrgCycleType.BILLING" :org-id="orgId" density="comfortable"
              class="mt-4 px-8" label="Select a cycle to generate report" :data-loader="cycleSelectorProps.dataLoader"
              @cycle-change="handleCycleChange" />
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-center">
            <div class="mx-2">
              <v-select color="primary" bg-color="surfaceBright" v-model="billingDistributionType" chips width="350px"
                label="Select billing strategy" variant="solo-filled" elevation="3" class="mt-4" density="comfortable"
                item-title="title" item-value="value" :items="billingDistributionTypes"
                @update:model-value="getBillingDistributionTypes()" />
            </div>
            <div class="mx-3 mb-2">
              <v-btn v-if="isDataAvailable" @click="exportFile" variant="tonal" density="default" elevation="3" rounded
                class="ma-2" icon="mdi-file-chart" size="small" />
              <v-tooltip activator="parent" location="bottom">
                Download Billing Report
              </v-tooltip>

            </div>
          </v-col>
        </v-row>
        <v-divider thickness="1" />
        <v-row>
          <v-col class="d-flex flex-column align-center justify-center">

            <v-skeleton-loader type="list-item" :loading="reportLoading" color="surface">
              <v-card flat class="h-100 mx-2 text-primary" variant="tonal" elevation="1" width="460">
                <v-card-title>
                  <h6 class="text-subtitle-1 text-center mb-n2 font-weight-bold"> Potential Reimbursement </h6>
                </v-card-title>
                <v-card-text class="text-center pb-1">
                  <v-divider thickness="1" />
                  <h6 class="text-h6 text-primary font-weight-black">${{ totalPotentialRevenue.toFixed(2) }} </h6>
                </v-card-text>
                <v-card-item class="pt-0">
                  <div class="text-primary d-flex justify-center my-1">
                    <div>

                      <v-chip color="primary" density="comfortable" class="mx-1">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2"> QHCP </span>
                        <span class="text-subtitle-1 font-weight-black">${{ totalPotentialQHCPRevenue.toFixed(2)
                          }}</span>
                      </v-chip>
                    </div>
                    &nbsp;
                    <div>
                      <v-chip color="primary" density="comfortable" class="mx-1">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2"> STAFF </span>
                        <span class="text-subtitle-1 font-weight-bold">${{ totalPotentialSTAFFRevenue.toFixed(2)
                          }}</span>
                      </v-chip>
                    </div>
                    &nbsp;
                    <div>
                      <v-chip color="primary" density="comfortable" class="mx-1">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2"> OTHER </span>
                        <span class="text-subtitle-1 font-weight-bold">${{ totalPotentialOTHERRevenue.toFixed(2)
                          }}</span>
                      </v-chip>
                    </div>
                  </div>
                </v-card-item>
              </v-card>
            </v-skeleton-loader>

          </v-col>
          <v-col class="d-flex flex-column align-center justify-center">
            <v-skeleton-loader type="list-item" :loading="reportLoading" class="header-time-card" color="transparent">
              <v-card flat class="h-100 mx-2 text-primary" variant="tonal" elevation="1" width="320">
                <v-card-title>
                  <h6 class="text-subtitle-1 text-center mb-n2 font-weight-bold"> Total Billed Time </h6>
                </v-card-title>
                <v-card-text class="text-center pb-1">
                  <v-divider thickness="1" />
                  <h6 class="text-h6 text-primary font-weight-black">{{
                    convertMillisecondsToReadableFormat(totalBillableTimeSpent)
                  }} </h6>
                </v-card-text>
                <v-card-item class="pt-0">
                  <div class="text-primary d-flex justify-center my-1">
                    <div>
                      <v-chip color="success" density="comfortable">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2">QHCP </span>
                        <span class="text-subtitle-1 font-weight-bold">{{
                          convertMillisecondsHMS(totalBillableTimeSpentQHCP)
                        }}</span>
                      </v-chip>
                    </div>
                    &nbsp;
                    <div>

                      <v-chip color="info" density="comfortable">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2">STAFF </span>
                        <span class="text-subtitle-1 font-weight-bold">{{
                          convertMillisecondsHMS(totalBillableTimeSpentStaff)
                        }}</span>
                      </v-chip>
                    </div>
                  </div>
                </v-card-item>
              </v-card>
            </v-skeleton-loader>

          </v-col>
          <v-col class="d-flex flex-column align-center justify-center">
            <v-skeleton-loader type="list-item" :loading="reportLoading" class="header-time-card" color="transparent">
              <v-card flat class="h-100 mx-2 text-primary" width="320" variant="tonal" elevation="1">
                <v-card-title>
                  <h6 class="text-subtitle-1 text-center mb-n2 font-weight-bold"> Total Recorded Time </h6>
                </v-card-title>
                <v-card-text class="text-center pb-1">
                  <v-divider thickness="1" />
                  <h6 class="text-h6 text-primary font-weight-black">{{
                    convertMillisecondsToReadableFormat(totalTimeSpent) }}
                  </h6>
                </v-card-text>
                <v-card-item class="pt-0">
                  <div class="text-primary d-flex justify-center my-1">
                    <div>
                      <v-chip color="success" density="comfortable">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2"> QHCP </span>
                        <span class="text-subtitle-1 font-weight-bold">{{
                          convertMillisecondsHMS(totalTimeSpentQHCP) }}</span>
                      </v-chip>
                    </div>
                    &nbsp;
                    <div>
                      <v-chip color="info" density="comfortable">
                        <span class="text-subtitle-2 text-primary font-weight-bold mx-2"> STAFF </span>
                        <span class="text-subtitle-1 font-weight-bold">{{ convertMillisecondsHMS(totalTimeSpentStaff)
                          }}</span>
                      </v-chip>
                    </div>
                  </div>
                </v-card-item>
              </v-card>
            </v-skeleton-loader>

          </v-col>
        </v-row>
        <v-divider thickness="1" class="mt-2" />
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-skeleton-loader v-if="reportLoading" type="table" color="surface" :rows="10" :columns="10" />
      <template v-else>
        <v-banner v-if="patientsWithBillingSyncInProgress > 0" color="warning" rounded class="my-1 pa-2 bg-orange-200 border-dotted" lines="one">
          <template v-slot:prepend>
            <v-icon color="warning">mdi-alert</v-icon>
          </template>
          <template v-slot:text>
            Billing is still being determined for <span class="text-red font-weight-bold">
              {{ patientsWithBillingSyncInProgress }}
            </span>  patient(s). Please reload the page to get the latest progress status
          </template>
        </v-banner>
        <div style="height: 75vh;" v-if="isDataAvailable" class="bg-surface">
          <v-grid id="org-billing-report-grid" :theme="theme.current.value.dark ? 'darkMaterial' : 'material'"
            :source="rows" :columns="columns" :readonly="true" :exporting="true" :filter="true" :resize="true"
            :column-types="columnTypes"
            :row-class="theme.current.value.dark ? 'rowHighlightClassDark' : 'rowHighlightClass'"
            @cell-custom-action="testCustomCellAction" @cell-click="testAction" />
        </div>
        <div v-else class="no-data-container mt-16">
          <div class="text-h6 font-weight-bold">No data available</div>
          <img src="../../../../assets/empty.png" alt="No data found" class="no-data-image" />
        </div>
      </template>
    </v-card-item>

    <v-snackbar color="error" class="text-white" v-model="showBillingReportErr" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showBillingReportErr = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
  <org-billing-configuration v-if="showBillingConfiguration" @billing-config-form-closed="hideBillingConfiguration"
    :billing-configuration-dependencies="billingConfigurationDependencies" :cycle="(selectedCycle as ICycle)"
    :org-id="orgId"
    :current-billing-info="{ totalPotentialRevenue, totalPotentialQHCPRevenue, totalPotentialSTAFFRevenue, totalPotentialOTHERRevenue, totalBillableTimeSpentQHCP, totalTimeSpent, totalTimeSpentQHCP, totalTimeSpentStaff, totalBillableTimeSpent, totalBillableTimeSpentStaff, billingProgramCodes }"></org-billing-configuration>


  <v-dialog v-model="confirmBillingStrategyChangeDialog" persistent width="500px">
    <v-card min-height="200px">
      <v-card-title class="font-weight-bold bg-surface pl-5">Confirm</v-card-title>
      <v-card-text class="text-h6">
        Are you sure you want to enable <b class="text-primary"> Basic </b> billing strategy?
      </v-card-text>
      <v-card-actions class="px-3 py-5">
        <v-row justify="end">
          <v-col sm="12" md="3" class="text-center">
            <v-btn color="primary" class="text-white text-none" density="comfortable" rounded="false"
              @click="cancelSwitchingBillingStrategy" variant="outlined" block>
              Cancel
            </v-btn>
          </v-col>
          <v-col sm="12" md="3" class="text-center">
            <v-btn color="primary" class="text-white text-none" variant="flat" type="submit" rounded="false"
              density="comfortable" block @click="persistDefaultBillingStrategy(selectedCycle as ICycle)">
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import CycleSelector from "@/components/cycle/CycleSelector.vue";
import { convertMillisecondsHMS, convertMillisecondsToReadableFormat } from "@/composables/DateUtility";
import { BillingCalculationStrategy, BillingSyncStatus } from '@/enums/billing.enum';
import { OrgCycleType } from "@/enums/cycle.enum";
import { IOrgBillingConfigurationDependencies, IOrgCycleBilling } from "@/interfaces/billing.interface";
import { ICycle, ICycleSelectorDependencies } from "@/interfaces/cycle.interface";
import { Provider } from "@/interfaces/patient.interface";
import DateTypePlugin from '@revolist/revogrid-column-date';
import NumberColumnType from '@revolist/revogrid-column-numeral';
import VGrid, { ColumnGrouping, ColumnRegular, ColumnType, DataType, VGridVueTemplate } from "@revolist/vue3-datagrid";
import { filter, find, first, flatten, includes, isEmpty, map, get } from 'lodash';
import moment from 'moment';
import { defineProps, onMounted, PropType, ref, watch } from 'vue';
import { useTheme } from "vuetify";
import { Program } from '../../../../enums/patient-program.enum';
import { CPTCode, IBillingCPTDistribution, ProgramBucketDistribution, IPatientBillingCycle } from '../../../../interfaces/billing.interface';
import { IAdditionalBillingInfo, IPatientProgramCycle } from '../../../../interfaces/patient-program-cycle.interface';
import OrgBillingConfiguration from "./OrgBillingConfiguration.vue";
import PatientBillingCustomizer from "./components/PatientBillingCustomizerGridWrapper.vue";
import { computed } from "vue";
import { AuditEvent } from '@/interfaces/audit-event.interface';
import { AuditEventDetail } from '@/enums/audit-event.enum';
import { BillingDistributionType, BillingDistributionTypes } from '../../../../enums/patient-program-cycle.enum';
import { useUserStore } from '../../../../store/modules/User';
import { useQuery } from '@tanstack/vue-query';


const theme = useTheme();
const columnTypes = {
  'numeric': new NumberColumnType('0,0'),
  'dollar': new NumberColumnType('$0,0.00'),
  'date': new DateTypePlugin() as unknown as ColumnType,
};

function testCustomCellAction(e: CustomEvent) {
  console.log('Custom cell action', e);
}
function testAction(e: CustomEvent) {
  console.log('Editor action', e);
}

const { orgId, dataLoader, billingConfigurationDependencies, auditEventCreation } = defineProps({
  orgId: {
    type: String,
    required: true
  },
  cycleSelectorProps: {
    type: Object as PropType<ICycleSelectorDependencies>,
    required: true
  },
  dataLoader: {
    type: Function as PropType<(orgId: string, cycle?: ICycle, billingType?: string) => Promise<IOrgCycleBilling>>,
    required: true
  },
  billingConfigurationDependencies: {
    type: Object as PropType<IOrgBillingConfigurationDependencies>,
    required: true,
  },
  auditEventCreation: {
    type: Function as PropType<(auditEventInput: AuditEvent) => Promise<AuditEvent>>,
    required: false,
  }
});

const staticColumns = ref([
  {
    prop: 'patientBilling',
    name: '',
    cellTemplate: VGridVueTemplate(PatientBillingCustomizer),
    size: 64,
    pin: 'colPinEnd',
    sortable: true
  },
  {
    prop: 'patientFirstName',
    name: 'First Name',
    sortable: true,
    pin: 'colPinStart',
    size: 125
  },
  {
    prop: 'patientLastName',
    name: 'Last Name',
    sortable: true,
    pin: 'colPinStart',
    size: 125
  },
  {
    prop: 'patientDob',
    name: 'DOB',
    sortable: true,
    columnType: 'date',
    pin: 'colPinStart',
    size: 120
  },
  {
    prop: 'providerName',
    name: 'Provider',
    sortable: true,
    pin: 'colPinStart',
    size: 120
  },
  {
    prop: 'patientQHCPRevenue',
    name: 'QHCP Reimbursement',
    sortable: true,
    columnType: 'dollar',
    pin: 'colPinStart',
  },
  {
    prop: 'patientSTAFFRevenue',
    name: 'STAFF Reimbursement',
    sortable: true,
    columnType: 'dollar',
    pin: 'colPinStart',
  },
  {
    prop: 'patientOTHERRevenue',
    name: 'Other Reimbursement',
    sortable: true,
    columnType: 'dollar',
    pin: 'colPinStart',
  },
  {
    prop: 'patientQHCPTimeSpentTotal',
    name: 'QHCP Time Recorded',
    sortable: true,
    pin: 'colPinStart',
    columnType: 'string',
  },
  {
    prop: 'patientStaffTimeSpentTotal',
    name: 'Staff Time Recorded',
    sortable: true,
    pin: 'colPinStart',
    columnType: 'string',
  },
  {
    prop: 'timeSpentTotal',
    name: 'Total Recorded',
    sortable: true,
    pin: 'colPinStart',
    columnType: 'string',
  },
] as (ColumnRegular | ColumnGrouping)[]);

const data = ref<IOrgCycleBilling>({} as IOrgCycleBilling);
const exportPlugin = ref();
const totalBillableTimeSpent = ref(0);
const totalBillableTimeSpentQHCP = ref(0);
const totalBillableTimeSpentStaff = ref(0);
const totalTimeSpent = ref(0);
const totalTimeSpentQHCP = ref(0);
const totalTimeSpentStaff = ref(0);
const totalPotentialRevenue = ref(0);
const totalPotentialQHCPRevenue = ref(0);
const totalPotentialSTAFFRevenue = ref(0);
const totalPotentialOTHERRevenue = ref(0);
const columns = ref<(ColumnRegular | ColumnGrouping)[]>();
const showBillingConfiguration = ref(false);
const selectedCycle = ref(null as null | ICycle);
const billingProgramCodes = ref();
const showBillingReportErr = ref(false);
const errorMessage = ref("");
const billingStrategy = ref(null as null | BillingCalculationStrategy);
const confirmBillingStrategyChangeDialog = ref(false);
const reportLoading = ref(false);
const patientsWithBillingSyncInProgress = ref(0);

const hideBillingConfiguration = () => {
  showBillingConfiguration.value = false;
  handleCycleChange(selectedCycle.value as ICycle);
}

const cancelSwitchingBillingStrategy = () => {
  confirmBillingStrategyChangeDialog.value = false;
}

const billingDistributionTypes = BillingDistributionTypes.filter(billingType => { return billingType });
const billingDistributionType = ref('');

const getBillingDistributionTypes = () => {
  loadData(selectedCycle.value as ICycle);
}

const themeConfigs = {
  activitydays: { columns: { cell: { style: { align: 'center' } } } },
  enrollmentTheme: {
    column: {
      header: {
        style: {
          color: 'white',
          background: 'rgba(0,0,0,0.75)'
        },
      },
      cell: {
        style: {
          color: 'white',
          background: 'rgba(0,0,0,0.75)'
        },
      }
    }
  },
  revenueTheme: {
    column: {
      header: {
        style: {
          color: 'white',
          background: 'rgba(0,0,0,0.75)'
        },
      },
      cell: {
        style: {
          color: 'white',
          background: 'rgba(0,0,0,0.75)'
        },
      }
    }
  },
  prgTheme: {
    RPM: {
      column: {
        header: {
          style: {
            color: 'white',
            background: 'rgba(0, 0, 44, 0.25)'
          },
        },
        cell: {
          style: {
            color: 'white',
            background: 'rgba(0, 0, 44, 0.25)'
          },
        }
      }
    },
    PCM: {
      column: {
        header: {
          style: {
            color: 'white',
            background: 'rgba(0, 0, 44, 0.5)'
          },
        },
        cell: {
          style: {
            background: 'rgba(0, 0, 44, 0.5)'
          },
        }
      }
    },
    CCM: {
      column: {
        header: {
          style: {
            color: 'white',
            background: 'rgba(0, 0, 44, 0.75)'
          },
        },
        cell: {
          style: {
            background: 'rgba(0, 0, 44, 0.75)'
          },
        }
      }
    },
    CCCM: {
      column: {
        header: {
          style: {
            color: 'white',
            background: 'rgba(0, 0, 44, 1)'
          },
        },
        cell: {
          style: {
            color: 'white',
            background: 'rgba(0, 0,44, 1)'
          },
        }
      }
    },
  },
  cptTheme: {
    QHCP: {
      column: {
        header: {
          style: {
            background: 'rgba(32, 64, 164, 0.5)'
          },
        },
        cell: {
          style: {
            background: 'rgba(32, 64, 164, 0.0625)'
          },
        }
      }
    },
    STAFF: {
      column: {
        header: {
          style: {
            background: 'rgba(64, 164, 164, 1)'
          },
        },
        cell: {
          style: {
            background: 'rgba(64, 164, 164, 0.125)'
          },
        }
      }
    },
    OTHER: {
      column: {
        header: {
          style: {
            background: 'rgba(164, 64, 104, 0.75)'
          },
        },
        cell: {
          style: {
            background: 'rgba(164, 32, 64, 0.25)'
          },
        }
      }
    },
  },

};

const rows = ref<DataType[]>([]);
const isDataAvailable = ref(false)

const exportFile = async () => {
  const timestamp = moment().format('MM-DD-YYYY-HH-mm-ss');
  const distributionType = billingDistributionType.value === BillingDistributionType.STANDARDIZED
    ? 'Basic' : billingDistributionType.value === BillingDistributionType.OPTIMIZED
      ? 'Optimized' : 'Override';

  (exportPlugin.value as any)?.exportFile({
    filename: `Billing report ${distributionType}-${timestamp}`,
  });

  const billingDistributionTypeMapping = {
    [BillingDistributionType.STANDARDIZED]: AuditEventDetail.STANDARDIZED_BILLING_DOWNLOADED,
    [BillingDistributionType.OPTIMIZED]: AuditEventDetail.OPTIMIZED_BILLING_DOWNLOADED,
    [BillingDistributionType.CUSTOMIZED]: AuditEventDetail.CUSTOMIZED_BILLING_DOWNLOADED,
  };

  const auditEventInfo = billingDistributionTypeMapping[billingDistributionType.value as BillingDistributionType];


  await auditEventCreation!({
    ...auditEventInfo,
    auditEventData: {
      orgId: useUserStore().$state.user.organizationId,
      billingDownloadType: distributionType,
    }

  })

};

const loadExportPlugin = () => {
  const grid = first(filter(document.getElementsByTagName('revo-grid'), (grid) => {
    return grid.id === 'org-billing-report-grid';
  }));
  if (grid) {
    grid.getPlugins().then(plugins => {
      exportPlugin.value = plugins.find((plugin) => 'exportFile' in plugin);
    });
    grid.addEventListener('beforeexport', handleBeforeExport);
  }
};

const handleBeforeExport = (event: any) => {
  event.detail.data.props = event.detail.data.props.filter((prop: string) => prop !== 'patientBilling')
};


const generateCPTCodeColProps = (cptCode: CPTCode) => {
  const { cell: cellTheme, header: headerTheme } = themeConfigs.cptTheme[cptCode.bucket].column;
  return {
    columnProperties: () => {
      return {
        style: headerTheme.style
      };
    },
    cellProperties: () => {
      return {
        style: cellTheme.style,
      };
    }
  }
}

const buildPrgSpecificCols = (program: string) => {
  const { cell: cellTheme, } = themeConfigs.activitydays.columns;

  const prgColDefs: Record<string, Function> = {
    [Program.RPM]: () => {
      return [{
        prop: `${program.toLocaleLowerCase()}activeDays`,
        name: 'Active Days',
        sortable: true,
        columnType: 'numeric',
        size: 120,
        cellProperties: () => ({ style: cellTheme.style }),
      },
      {
        prop: `${program.toLocaleLowerCase()}firstMeasurementDate`,
        name: 'First Reading',
        columnType: 'date',
        sortable: true,
        size: 120
      }
      ];
    },
    ['DEFAULT']: () => []
  };
  return (prgColDefs[program] || prgColDefs['DEFAULT'])();
}

const buildColumnDefinitions = (data: IOrgCycleBilling) => {
  const { programCodes } = data;
  const sortedPrograms =  //sort the programCodes in the order of RPM, PCM, CCM, CCCM
    ['RPM', 'PCM', 'CCM', 'CCCM'].reduce((acc, program) => {
      acc[program] = programCodes[program as keyof typeof programCodes];
      return acc;
    }, {} as Record<string, CPTCode[]>);

  const programColumns = map(sortedPrograms, (codes, program) => {
    return {
      name: program,
      children: [
        ...map(codes, (cptCode) => {
          const { columnProperties, cellProperties } = generateCPTCodeColProps(cptCode);
          return {
            prop: `${program.toLowerCase()}${cptCode.code}`,
            name: cptCode.code,
            sortable: true,
            columnType: 'numeric',
            columnProperties,
            cellProperties,
          };
        }),
        ...buildPrgSpecificCols(program),
        {
          prop: `qhcp${program.toLowerCase()}TimeBilled`,
          name: `QHCP Time Rec`,
          sortable: true,
          columnType: 'string',
          size: 140
        },
        {
          prop: `staff${program.toLowerCase()}TimeBilled`,
          name: `STAFF Time Rec`,
          sortable: true,
          columnType: 'string',
          size: 140
        },
        {
          prop: `${program.toLowerCase()}Enrollment`,
          name: `Enrollment Date`,
          columnType: 'date',
          size: 120,
          sortable: true,
          columnProperties: () => {
            const { header: headerTheme } = themeConfigs.enrollmentTheme.column;
            return {
              style: headerTheme.style
            };
          },
        }, {
          prop: `qhcp${program.toLowerCase()}Revenue`,
          name: `QHCP Reimbursement`,
          sortable: true,
          columnType: 'dollar',
          columnProperties: () => {
            const { header: headerTheme } = themeConfigs.revenueTheme.column;
            return {
              style: headerTheme.style
            };
          },
        }, {
          prop: `staff${program.toLowerCase()}Revenue`,
          name: `Staff Reimbursement`,
          sortable: true,
          columnType: 'dollar',
          columnProperties: () => {
            const { header: headerTheme } = themeConfigs.revenueTheme.column;
            return {
              style: headerTheme.style
            };
          },
        }, {
          prop: `other${program.toLowerCase()}Revenue`,
          name: `Other Reimbursement`,
          sortable: true,
          columnType: 'dollar',
          columnProperties: () => {
            const { header: headerTheme } = themeConfigs.revenueTheme.column;
            return {
              style: headerTheme.style
            };
          },
        }, {
          prop: `${program.toLowerCase()}BillableBucket`,
          name: `Billable Bucket`,
          sortable: true,
          columnType: 'string',
        }],
      columnProperties: () => {
        const prg = program as keyof typeof themeConfigs.prgTheme;
        const { header: headerTheme } = themeConfigs.prgTheme[prg].column;
        return {
          style: headerTheme.style
        };
      },
      cellProperties: () => {
        const prg = program as keyof typeof themeConfigs.prgTheme;
        const { cell: cellTheme } = themeConfigs.prgTheme[prg].column;
        return {
          style: cellTheme.style
        };
      },
    };
  });

  columns.value = [...staticColumns.value, ...programColumns];
};

const buildPrgSpecificCells = (program: string, prgInCycle: IPatientProgramCycle) => {
  const { daysActive: activeDays, firstMeasurementDate } = prgInCycle.cycleStatus;
  return {
    [`${program.toLocaleLowerCase()}activeDays`]: activeDays,
    [`${program.toLocaleLowerCase()}firstMeasurementDate`]: firstMeasurementDate ? moment.unix(Number.parseInt(firstMeasurementDate) / 1000).local().format('MM/DD/YYYY') : "N/A"
  };
}


const buildProgramData = (programCodes: { [key in Program]: CPTCode[] }, billingDistributions: IBillingCPTDistribution[], programsInCycle: IPatientProgramCycle[], additionalBillingInfo: IAdditionalBillingInfo) => {
  let patientTotalRevenue = 0;
  let patientTotalQHCPRevenue = 0;
  let patientTotalSTAFFRevenue = 0;
  let patientTotalOtherRevenue = 0;
  let patientBillableTotalTime = 0;
  let patientBillableQHCPTime = 0;
  let patientBillableSTAFFTime = 0;

  const progDataCells = flatten(map(programCodes, (codes, program) => {
    const prgInCycle = find(programsInCycle, (programInCycle) => {
      return programInCycle.program === program
    })!;
    if (prgInCycle) {
      const programEnrollmentDate = prgInCycle.programStartDate;
      const knownCodes = map(codes, 'code');
      const distBucketKeys = {
        [Program.CCM]: 'ccmBucket',
        [Program.CCCM]: 'cccmBucket',
        [Program.PCM]: 'pcmBucket',
        [Program.RPM]: 'rpmBucket'
      };
      const prgDistBucketKey = distBucketKeys[program as Program];
      const qualifyingDistributions = filter(billingDistributions, distribution => {
        return !!distribution[(prgDistBucketKey as keyof IBillingCPTDistribution)] &&
          includes(knownCodes, distribution.cptCode.code)
      });
      let progRevenueTotal = 0;
      let progRevenueQHCPTotal = 0;
      let progRevenueSTAFFTotal = 0;
      let progRevenueOtherTotal = 0;
      let progTimeTotal = 0;
      let progQHCPTime = 0;
      let progSTAFFTime = 0;
      const programRows = map(qualifyingDistributions, distribution => {
        const { cptCode } = distribution;
        const prgBucket = distribution[prgDistBucketKey as keyof IBillingCPTDistribution] as ProgramBucketDistribution;
        const billableBucket = additionalBillingInfo[program];
        progRevenueTotal = progRevenueTotal + prgBucket.revenue;
        progTimeTotal = progTimeTotal + prgBucket.timeInCycle;
        if (cptCode.bucket === 'QHCP') {
          progQHCPTime = progQHCPTime + prgBucket.timeInCycle;
          progRevenueQHCPTotal = progRevenueQHCPTotal + prgBucket.revenue;
        } else if (cptCode.bucket === 'STAFF') {
          progSTAFFTime = progSTAFFTime + prgBucket.timeInCycle;
          progRevenueSTAFFTotal = progRevenueSTAFFTotal + prgBucket.revenue;
        } else if (cptCode.bucket === 'OTHER') {
          progRevenueOtherTotal = progRevenueOtherTotal + prgBucket.revenue;
        }
        return {
          [`${program.toLowerCase()}${cptCode.code}`]: (billableBucket?.billableBucket === cptCode.bucket) || cptCode.bucket === 'OTHER' ? prgBucket.billingCount : undefined
        }
      });
      const billableBucket = additionalBillingInfo && additionalBillingInfo[program] ? additionalBillingInfo[program].billableBucket : 'N/A';
      const billableQHCPRevenue = billableBucket === 'QHCP' ? progRevenueQHCPTotal : 0;
      const billableStaffRevenue = billableBucket === 'STAFF' ? progRevenueSTAFFTotal : 0;
      const billableQHCPTime = billableBucket === 'QHCP' ? progQHCPTime : 0;
      const billableStaffTime = billableBucket === 'STAFF' ? progSTAFFTime : 0;
      patientTotalQHCPRevenue += billableQHCPRevenue;
      patientTotalSTAFFRevenue += billableStaffRevenue;
      patientTotalOtherRevenue += progRevenueOtherTotal;
      patientTotalRevenue += billableQHCPRevenue + billableStaffRevenue + progRevenueOtherTotal;

      patientBillableTotalTime = patientBillableTotalTime + billableQHCPTime + billableStaffTime;
      patientBillableQHCPTime += billableQHCPTime;
      patientBillableSTAFFTime += billableStaffTime;
      const progSpecificCells = buildPrgSpecificCells(program, prgInCycle);
      return [
        ...programRows,
        { ...progSpecificCells },
        { [`qhcp${program.toLowerCase()}TimeBilled`]: `${convertMillisecondsHMS(billableQHCPTime)}` },
        { [`staff${program.toLowerCase()}TimeBilled`]: `${convertMillisecondsHMS(billableStaffTime)}` },
        { [`qhcp${program.toLowerCase()}Revenue`]: `${billableQHCPRevenue.toFixed(2)}` },
        { [`staff${program.toLowerCase()}Revenue`]: `${billableStaffRevenue.toFixed(2)}` },
        { [`other${program.toLowerCase()}Revenue`]: `${progRevenueOtherTotal.toFixed(2)}` },
        { [`${program.toLowerCase()}Revenue`]: `${progRevenueTotal.toFixed(2)}` },
        { [`${program.toLowerCase()}Enrollment`]: moment.unix(Number.parseInt(programEnrollmentDate) / 1000).format("MM/DD/YYYY") },
        { [`${program.toLowerCase()}Time`]: convertMillisecondsHMS(progTimeTotal) },
        { [`${program.toLowerCase()}BillableBucket`]: billableBucket }
      ];
    }
  }));
  return { progDataCells, patientTotalRevenue, patientBillableTotalTime, patientBillableQHCPTime, patientBillableSTAFFTime, patientTotalQHCPRevenue, patientTotalSTAFFRevenue, patientTotalOtherRevenue };
}

const buildDataRows = (data: IOrgCycleBilling) => {
  const { patientBillingsInCycle, programCodes } = data;
  billingProgramCodes.value = programCodes;
  let totalRevenue = 0;
  let totalQHCPRevenue = 0;
  let totalSTAFFRevenue = 0;
  let totalOtherRevenue = 0;
  let totalTime = 0;
  let totalQHCPTime = 0;
  let totalSTAFFTime = 0;
  let totalBillableTime = 0;
  let totalBillableQHCPTime = 0;
  let totalBillableSTAFFTime = 0;
  let totalpatientsWithBillingSyncInProgress = 0;
  const patientBillingRows = map(patientBillingsInCycle, (patientBilling) => {
    const { billingDistributions, programsInCycle, additionalBillingInfo, isCustomized } = patientBilling;
    const { patientTotalRevenue, patientBillableTotalTime, patientBillableQHCPTime, patientBillableSTAFFTime, progDataCells, patientTotalQHCPRevenue, patientTotalSTAFFRevenue, patientTotalOtherRevenue } = buildProgramData(programCodes, billingDistributions, programsInCycle, additionalBillingInfo);
    const programDataRows = Object.assign({}, ...progDataCells);
    totalRevenue = totalRevenue + patientTotalRevenue;
    totalQHCPRevenue += patientTotalQHCPRevenue;
    totalSTAFFRevenue += patientTotalSTAFFRevenue;
    totalOtherRevenue += patientTotalOtherRevenue;
    if (patientBilling.timeSpent) {
      const { qhcp, staff } = patientBilling.timeSpent;
      totalQHCPTime += (qhcp || 0)
      totalSTAFFTime += (staff || 0);
      totalTime += (staff || 0) + (qhcp || 0);
    }

    totalBillableTime += patientBillableTotalTime;
    totalBillableQHCPTime += patientBillableQHCPTime;
    totalBillableSTAFFTime += patientBillableSTAFFTime;
    const patientStaffTimeSpentTotalMS = patientBilling.timeSpent?.staff || 0;
    const patientQHCPTimeSpentTotalMS = patientBilling.timeSpent?.qhcp || 0;

    const billingSyncStatus = get(patientBilling, 'patientProfileState.billing.billingSync');    
    if (billingSyncStatus && billingSyncStatus !== BillingSyncStatus.COMPLETED) {
      totalpatientsWithBillingSyncInProgress++;
    }

    return {
      patientFirstName: patientBilling.firstName,
      patientLastName: patientBilling.lastName,
      patientDob: patientBilling.dob,
      providerName: `${(patientBilling.provider as Provider)?.firstName} ${(patientBilling.provider as Provider)?.lastName}`,
      patientRevenue: `${patientTotalRevenue.toFixed(2)}`,
      ...programDataRows,
      patientBilling: { ...patientBilling, isCustomizable: isCustomizable.value, billingDistributionType: billingDistributionType.value, orgId, cycle: selectedCycle.value },
      patientQHCPRevenue: patientTotalQHCPRevenue,
      patientSTAFFRevenue: patientTotalSTAFFRevenue,
      patientOTHERRevenue: patientTotalOtherRevenue,
      patientStaffTimeSpentTotal: convertMillisecondsHMS(patientStaffTimeSpentTotalMS),
      patientQHCPTimeSpentTotal: convertMillisecondsHMS(patientQHCPTimeSpentTotalMS),
      timeSpentTotal: convertMillisecondsHMS(patientStaffTimeSpentTotalMS + patientQHCPTimeSpentTotalMS),
      rowHighlightClass: getRowHighlightClass(patientBilling),
      rowHighlightClassDark: getRowHighlightClass(patientBilling, true),
      isCustomized: isCustomized ? 'Y' : 'N',
    }
  });

  totalPotentialRevenue.value = totalRevenue;
  totalPotentialQHCPRevenue.value = totalQHCPRevenue;
  totalPotentialSTAFFRevenue.value = totalSTAFFRevenue;
  totalPotentialOTHERRevenue.value = totalOtherRevenue;
  totalBillableTimeSpent.value = totalBillableTime;
  totalBillableTimeSpentQHCP.value = totalBillableQHCPTime;
  totalBillableTimeSpentStaff.value = totalBillableSTAFFTime;
  totalTimeSpent.value = totalTime;
  totalTimeSpentQHCP.value = totalQHCPTime;
  totalTimeSpentStaff.value = totalSTAFFTime;
  patientsWithBillingSyncInProgress.value = totalpatientsWithBillingSyncInProgress;
  rows.value = [...patientBillingRows];
}

const getRowHighlightClass = (patientBilling: IPatientBillingCycle, isDark?: boolean) => {
  const isCustomizedReport = billingDistributionType.value === 'customizedBillingCPTDistributions';
  if (isCustomizedReport) {
    if (isLowBilling(patientBilling)) {
      return isDark ? 'low-billing-highlight-dark' : 'low-billing-highlight';
    } else if (isOverrideSuggestionEligible(patientBilling)) {
      return isDark ? 'override-suggestion-highlight-dark' : 'override-suggestion-highlight';
    } else if (patientBilling.isCustomized) {
      return isDark ? 'customized-row-highlight-dark' : 'customized-row-highlight';
    }
  }
  return 'transparent';
}

const isOverrideSuggestionEligible = (patientBilling: IPatientBillingCycle) => {
  const { billingDistributions, timeSpent } = patientBilling;
  const totalQHCPTime = timeSpent?.qhcp || 0;
  const totalStaffTime = timeSpent?.staff || 0;
  const totalPatientTime = totalQHCPTime + totalStaffTime;
  const totalCPTTime = billingDistributions.reduce((acc, distribution) => {
    const { cccmBucket, ccmBucket, pcmBucket, rpmBucket } = distribution;
    return acc + (ccmBucket?.timeInCycle || 0) + (cccmBucket?.timeInCycle || 0) + (pcmBucket?.timeInCycle || 0) + (rpmBucket?.timeInCycle || 0);
  }, 0);
  return totalPatientTime > totalCPTTime * 1.20;
};

const isLowBilling = (patientBilling: IPatientBillingCycle) => {
  const { billingDistributions } = patientBilling;
  const totalRevenue = billingDistributions.reduce((acc, distribution) => {
    const { cccmBucket, ccmBucket, pcmBucket, rpmBucket } = distribution;
    return acc + (ccmBucket?.revenue || 0) + (cccmBucket?.revenue || 0) + (pcmBucket?.revenue || 0) + (rpmBucket?.revenue || 0);
  }, 0);
  return !(totalRevenue > 0);
};



const loadData = async (cycle?: ICycle) => {
  reportLoading.value = true;
  data.value = {} as IOrgCycleBilling;
  try {
    const result = await dataLoader(orgId, cycle, billingDistributionType.value);
    data.value = result;
    /* find a better solution over using setTimeout to wait for the HTML rendering */
    setTimeout(() => loadExportPlugin());
  } catch (error) {
    errorMessage.value = 'failed to fetch billing report';
    showBillingReportErr.value = true;
    reportLoading.value = false;
    setDefaultValues();
  }
  reportLoading.value = false;
  return data.value || {};
};

const persistDefaultBillingStrategy = async (cycle: ICycle) => {
  confirmBillingStrategyChangeDialog.value = false;
  await billingConfigurationDependencies.persistEmulatedOrgBilling(cycle, { billingStrategy: BillingCalculationStrategy.REUSE_UNBILLABLE_QHCP_MINUTES }, false);
  await loadData(cycle);
  billingStrategy.value = BillingCalculationStrategy.REUSE_UNBILLABLE_QHCP_MINUTES
}

const loadBillingConfiguration = async (cycle: ICycle) => {
  try {
    const billingConfiguration = await billingConfigurationDependencies.getCurrentBillingConfiuration(cycle);
    if (billingConfiguration) {
      billingStrategy.value = billingConfiguration.configuration.billingStrategy;
    }
  } catch (error) {
    errorMessage.value = 'Failed to get billing configuration';
    showBillingReportErr.value = true;
  }
}


const handleCycleChange = (payload: ICycle) => {
  if (payload) {
    selectedCycle.value = payload;
    loadBillingConfiguration(payload);
    loadData(payload);
  }
};

const isCustomizable = computed(() =>
  billingDistributionType.value === 'customizedBillingCPTDistributions',
);



const setDefaultValues = () => {
  totalPotentialRevenue.value = 0;
  totalPotentialQHCPRevenue.value = 0;
  totalPotentialSTAFFRevenue.value = 0;
  totalPotentialOTHERRevenue.value = 0;
  totalBillableTimeSpent.value = 0;
  totalBillableTimeSpentQHCP.value = 0;
  totalBillableTimeSpentStaff.value = 0;
  totalTimeSpent.value = 0;
  totalTimeSpentQHCP.value = 0;
  totalTimeSpentStaff.value = 0;
  isDataAvailable.value = false;
};

useQuery({
  queryKey: [`org-billing-report`],
  queryFn: () => loadData(selectedCycle.value as ICycle),
});

watch(data, (newData) => {
  if (newData) {
    const dataVal = data.value!;
    if (isEmpty(dataVal)) {
      setDefaultValues();
    } else if (!isEmpty(dataVal)) {
      buildColumnDefinitions(dataVal);
      buildDataRows(dataVal);
      isDataAvailable.value = true;
    }
  }
});


watch(() => billingDistributionType.value, (newVal) => {
  if (newVal && newVal === BillingDistributionType.CUSTOMIZED) {
    staticColumns.value = [...staticColumns.value, {
      prop: 'isCustomized',
      name: 'Overridden',
      pin: 'colPinStart',
      sortable: true
    }]
  }
});

onMounted(() => {
  billingDistributionType.value = billingDistributionTypes[0].value;
});

</script>

<style lang="scss">
@import '../../../../assets/styles/vt-custom-style-variables.scss';

.cycle-select {
  max-width: 260px;
}

.header-time-card {
  min-width: 340px;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data-image {
  width: 250px;
  height: 250px;
}

.customized-row-highlight {
  background-color: $customized-row-highlight-bg;
}

.override-suggestion-highlight {
  background-color: $override-suggestion-highlight-bg;
}

.low-billing-highlight {
  background-color: $low-billing-highlight-bg;
}

.customized-row-highlight-dark {
  background-color: $customized-row-highlight-bg-dark;
}

.override-suggestion-highlight-dark {
  background-color: $override-suggestion-highlight-bg-dark;
}

.low-billing-highlight-dark {
  background-color: $low-billing-highlight-bg-dark;
}

revo-grid .attribution {
  display: none;
}
</style>
