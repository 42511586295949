<template>
  <v-card flat class="px-4 pt-5 bg-surfContainerLowest">
    <EditorContent :editor="editor" />
    <div class="text-right my-5">
      <v-btn :loading="isLoading" elevation="3" rounded="false" @click="emitContent">Save</v-btn>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { ref, watch } from "vue";

const props = defineProps({
  noteContent: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  }
});

const emits = defineEmits(['updateNoteContent']);
const loading = ref(false);

const editor = useEditor({
  content: props.noteContent ?? "",
  extensions: [StarterKit,],
  editorProps: {
    attributes: {
      class: 'custom-editor',
    },
  },
});

const emitContent = () => {
  const content = editor.value?.getText();
  emits('updateNoteContent', content);
};

watch(() => props.isLoading, (newValue) => {
  loading.value = newValue;
})
</script>

<style>


.custom-editor {
  min-height: 400px;
  padding: 10px;
  border: 1px solid #ccc;
}

.ProseMirror-focused {
  outline: none;
}
</style>
