<template>
  <v-card flat>
    <v-row>
      <v-col cols="12">
        <v-card height="80px" elevation="3" class="ma-2 bg-surfContainerLowest">
          <div class="d-flex align-center h-100">
            <v-card-title class="text-h5 font-weight-bold text-primary px-6">Edit Note</v-card-title>
            <v-spacer />
            <v-btn :rounded="false" size="40px" class="mx-4" elevation="3" icon="mdi-close"
              @click="$emit('closeNoteEditHandler')"></v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="5" class="mt-n3">
        <v-card elevation="3" class="bg-surfContainerLowest px-4 mx-2">
          <WYSIWYGEditor @update-note-content="updatingNoteContent" :note-content="existingNoteData.noteContent"
            :is-loading="isLoading" />
        </v-card>
      </v-col>
      <v-col cols="7" class="mt-n3">
        <v-card elevation="3" class="mx-3">
          <PatientSnapshotInfo :content-cycle="existingNoteData.createdAt" :isEditForm="true"
            :patient-id="patientProfile.patientId" :snapshot-patient-info="getPatientDataDependencies.snapshotPatientInfo
              " :patient-vital-config-loader="getPatientDataDependencies.getPatientVitalsThresholdConfig
                " :is-data-updated="isNoteEdited" />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  <v-snackbar color="success" class="text-white" v-model="showSuccessMessage" location="top right">
    Note updated successfully
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="red" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script lang="ts" setup>
import { ref, defineEmits, PropType, defineProps } from "vue";
import { ListNotes } from "@/interfaces/notes.interface";
import { IPatientProfileProps } from "@/interfaces/patient.interface";
import { IPatientData } from "@/interfaces/Service.interface";
import PatientSnapshotInfo from "../../patient/snapshot/PatientSnapshotInfo.vue";
import WYSIWYGEditor from '../../common/editors/WYSIWYGEditor.vue';
import { notesEditedEventBusKey } from '@/events/bus-keys/notes-timelog-event.bus-keys';
import { useEventBus } from "@vueuse/core";

const emits = defineEmits(["closeNoteEditHandler", "noteEdited"]);

const props = defineProps({
  existingNoteData: {
    type: Object as PropType<ListNotes>,
    required: true,
  },
  getPatientDataDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
});

const { existingNoteData, getPatientDataDependencies, patientProfile } = props;

const isLoading = ref(false);
const isNoteEdited = ref(false);

const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref("");
const noteEditedEventBus = useEventBus(notesEditedEventBusKey);

const updatingNoteContent = async (noteContent: string) => {
  const { noteId, title, contentType, source } = existingNoteData!;
  isLoading.value = true;
  await getPatientDataDependencies
    .updatePatientNote({
      noteId: noteId!,
      input: {
        contentType: contentType!,
        title: title!,
        message: noteContent,
        source: source! ?? null,
      },
    })
    .then((response) => {
      showSuccessMessage.value = !!response;
      isNoteEdited.value = true;
      emits("noteEdited", !!response);
      noteEditedEventBus.emit('note edited successfully')
    })
    .catch((error: Error) => {
      showErrorMessage.value = true;
      errorMessage.value = error.message;
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
