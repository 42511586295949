<template>
  <v-card color="surfContainer" class="w-100 h-100">
    <div class="d-flex flex-column">
      <v-tabs v-model="dataTab" color="primary" class="bg-surface text-primary" density="compact">
        <v-tab :value="PATIENT_ACTIONS.TASKS">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_ACTIONS.TASKS }} </span>
        </v-tab>
        <v-tab :value="PATIENT_ACTIONS.TIME_LOG">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_ACTIONS.TIME_LOG }} </span>
        </v-tab>
        <v-tab :value="PATIENT_ACTIONS.NOTES">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_ACTIONS.NOTES }} </span>
        </v-tab>
      </v-tabs>
      <v-window v-model="dataTab">
        <v-window-item :value="PATIENT_ACTIONS.TASKS">
          <PatientTask :patient-profile="patientProfile" :activity-loader="getTaskDependencies.listTaskActivities"
            :add-comment-handler="getTaskDependencies.createComment" :is-task-board-task-view="isTaskBoardTaskView"
            :get-task-detail-dependencies="getTaskDetailsDependencies"
            :list-patient-task-loader="getTaskDependencies.listPatientTask"
            :get-org-task-status-states="getTaskDependencies.getOrgTaskStatusStates"
            :requestor-input="getTaskDetailsDependencies.requestorInput"
            :assignee-input="getTaskDetailsDependencies.assigneeInput"
            :edit-task-detail="getTaskDetailsDependencies.editTaskDetail!"
            :task-queue-input="getTaskDetailsDependencies.taskQueueInput!"
            :submit-action-delegate="getTaskDetailsDependencies.addTaskHandler!" />
        </v-window-item>
        <v-window-item :value="PATIENT_ACTIONS.TIME_LOG">
          <PatientTimeLog :patient-profile="patientProfile" :get-action-dependencies="getActionDependencies"
            :list-assignees="getNotesDependencies.listAssignees">
          </PatientTimeLog>
        </v-window-item>
        <v-window-item :value="PATIENT_ACTIONS.NOTES">
          <PatientNotes :add-note-handler="getNotesDependencies.createNote"
            :note-data-loader="getNotesDependencies.getNotes" :task-queue-loader="getNotesDependencies.listTaskQueue"
            :list-assignees="getNotesDependencies.listAssignees" :get-patient-data-dependencies="getTaskDetailsDependencies"
            :user-handler="getNotesDependencies.getLoggedInUserInfo"
            :assign-task-handler="getNotesDependencies.assignTaskToNote" :patient-profile="patientProfile" />
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import PatientNotes from '../../../views/note/PatientNotes.vue';
import PatientTask from '../../../views/patient/PatientTask.vue';
import { PATIENT_ACTIONS } from '../../../../enums/patient.enum';
import { IActionDependencies, IPatientMonitor } from '@/interfaces/Service.interface';
import { pick } from 'lodash';
import { IPatientProfileProps } from '../../../../interfaces/patient.interface';
import PatientTimeLog from './PatientTimeLog.vue';
import { IPatientData } from '../../../../interfaces/Service.interface';

const { getActionDependencies, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  getActionDependencies: {
    type: Object as PropType<IActionDependencies>,
    required: true
  },
  getTaskDependencies: {
    type: Object as PropType<IPatientMonitor>,
    required: true
  },
  getTaskDetailsDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  isTaskBoardTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const getNotesDependencies = pick(getActionDependencies, ['createNote', 'getNotes', 'listAssignees', 'assignTaskToNote', 'listTaskQueue', 'getLoggedInUserInfo', 'listActivities', 'createAddTimeLog'])
const getTaskDependencies = pick(getActionDependencies, ['listPatientTask', 'createComment', 'listTaskActivities', 'getOrgTaskStatusStates'])

const dataTab = ref(PATIENT_ACTIONS.TASKS);
</script>
