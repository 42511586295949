<template>
  <v-row class="mb-4">
    <v-col cols="6" class="text-center">
      <div v-if="imageSrc">
        <v-img :src="imageSrc" min-height="160" max-height="160" contain></v-img>
      </div>
      <div v-else class="outlined-box"></div>
      <div>
        <p v-if="isNotValidFile" class="text-error mt-4">
          File size exceeds 1 MB. Please upload a smaller image.
        </p>
      </div>
    </v-col>
    <v-col cols="6" class="text-center d-flex justify-center items-center">
      <div>
        <v-btn elevation="3" outlined rounded="false" color="primary" @click="browseFile">Upload Logo</v-btn>
        <input ref="fileInput" type="file" :accept="acceptFileTypes.join(',')" style="display: none" @change="onFileSelected" />
        <v-btn elevation="3" class="ma-4" @click="clearImage" variant="tonal" rounded="false">Clear</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { LogoTypes } from "@/enums/document-upload.enum";
import { first, set } from "lodash";
import { ref, defineProps, watch } from "vue";

const fileInput = ref<HTMLInputElement | null>(null);
const imageSrc = ref<string | null>(null);
const binaryData = ref<string | null>(null);
const isNotValidFile = ref(false);
const acceptFileTypes = Object.values(LogoTypes);

const props = defineProps({
  binaryImage: {
    type: [String, null],
    required: true,
  },
});

const emits = defineEmits(["imageUploaded", "isValidFile"]);

const browseFile = () => {
  fileInput.value?.click();
};

const onFileSelected = (event: Event) => {
  const file = first((event.target as HTMLInputElement).files);

  if (file) {
    isValidFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      if (arrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);
        binaryData.value = Array.from(uint8Array)
          .map((byte) => byte.toString(2).padStart(8, "0"))
          .join("");

        binaryToImageConvertor(binaryData.value);
        emits("imageUploaded", binaryData.value);
      }
    };
    reader.readAsArrayBuffer(file);
  }
};

const isValidFile = (file: File) => {
  const maxFileSize = (import.meta.env.VITE_ORG_LOGO_FILE_SIZE || 1) * 1024 * 1024;
  const isFileValid = file && file.size <= maxFileSize;
  isNotValidFile.value = !isFileValid;
  emits("isValidFile", isFileValid);
}

const binaryToImageConvertor = (binary: string) => {
  const byteArray = binaryStringToUint8Array(binary);
  const blob = new Blob([byteArray]);
  imageSrc.value = URL.createObjectURL(blob);
};

const binaryStringToUint8Array = (binaryString: string) =>
  Uint8Array.from({ length: binaryString.length / 8 }, (_, i) =>
    parseInt(binaryString.slice(i * 8, i * 8 + 8), 2)
  );

const clearImage = () => {
  imageSrc.value = null;
  binaryData.value = null;
  isNotValidFile.value = false;
  emits("isValidFile", true);
  emits("imageUploaded", binaryData.value);
  if (fileInput.value) {
    set(fileInput, 'value.value', '');
  }
};

watch(
  () => props.binaryImage,
  (newValue) => {
    binaryToImageConvertor(newValue!);
  }
);
</script>

<style>
.outlined-box {
  border: 0.2px solid;
  border-radius: 8px;
  padding: 4px;
  min-height: 180px;
}
</style>
