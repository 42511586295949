import gql from "graphql-tag";

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($organization: OrganizationInput!) {
    createOrganization(organization: $organization) {
      name
      npi
      orgType
      addressLine1
      addressLine2
      city
      zip
      state
      country
      orgId
      orgConfig {
        theme {
          logoKey
        }
      }
    }
  }
`;

export const GET_ORGS = gql`
  query ($limit: Int, $offset: Int) {
    listOrganizations(limit: $limit, offset: $offset) {
      count
      organizations {
        orgId
        name
        npi
        orgType
        addressLine1
        addressLine2
        zip
        state
        city
        country
        orgConfig {
          theme {
            logoKey
          }
        }
      }
    }
  }
`;

export const GET_ORG_BY_ID = gql`
  query getOrganizationById($orgId: String!) {
    getOrganizationById(orgId: $orgId) {
      orgId
      name
      npi
      orgType
      addressLine1
      addressLine2
      zip
      state
      city
      country
      timeZone
      orgConfig {
        theme {
          logoKey
        }
      }
    }
  }
`;
export const EDIT_ORGANIZATION = gql`
  mutation editOrganization($organization: EditOrganizationInput!) {
    editOrganization(organization: $organization) {
      orgId
      name
      npi
      orgType
      addressLine1
      addressLine2
      zip
      state
      city
      country
      orgConfig {
        theme {
          logoKey
        }
      }
    }
  }
`;
